import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { CallStatistics } from '@/api/homelook';
import { getStructureListArr } from '@/api/PublicAPI';
import { getUserList, getlower_structure_list } from '@/api/updateUserInfo/structure';
export default {
  components: {
    Head: Head
  },
  data: function data() {
    return {
      // 系统教程
      tab: 1,
      activeName: 'first',
      course: this.$route.query.course,
      prohibit: false,
      searchShow: false,
      tableShow: false,
      // 系统教程
      // 埋点是否显示
      BuriedData1: [],
      Buried: {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      },
      finalFollow: '',
      structure_id: '',
      start_follow_time: '',
      //时间（开始）
      end_follow_time: '',
      //时间（结束）
      typeShow: false,
      operatorShow: false,
      userNameshow: false,
      BelongingShow: false,
      operationDataShow: false,
      // 埋点是否显示
      pagesize: 15,
      page: 1,
      total: 0,
      loading: true,
      title: '通话时长统计',
      value1: '',
      buried: [],
      auth_rule_id: '',
      CallStatistics_teacherid: '',
      CallStatistics_teachername: [],
      //顾问老师查询
      CallStatistics_id: '',
      bumenoptions: [],
      //部门
      CallStatistics_list: [],
      CallStatistics_time: '',
      //日期
      start_time: '',
      end_time: '',
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() >= Date.now(); //禁止选择以后的时间
        }
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    // 控制表格滚动条
    var that = this;
    this.buried = this.$store.getters.JsonArr1;
    if (this.$store.getters.Atter.indexOf("查看") != -1) {
      this.tableShow = true;
    }
    if (this.$store.getters.Atter.indexOf("搜索") != -1) {
      this.searchShow = true;
    }
    if (this.course != 1) {
      this.buriedPoint('查看');
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
      var sharing_time = [new Date().setHours(-23, 0, 0, 0), new Date().setHours(-23, 59, 59, 999)];
      this.start_time = String(sharing_time[0]).substr(0, 10);
      this.end_time = String(sharing_time[1]).substr(0, 10);
      this.GetList();
      this.getStructure();
      this.Departmentaddress(); //顾问老师通讯录
    } else {}
  },
  methods: {
    // 埋点列表筛选
    operatorClick: function operatorClick() {
      //操作人
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    userNamesClick: function userNamesClick() {
      //用户名
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    noteAppendedClick: function noteAppendedClick() {
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    FinalFollowUpEs: function FinalFollowUpEs() {
      //最后跟进日期筛选
      this.page = 1;
      if (this.finalFollow == null) {
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.page, this.pagesize);
      } else {
        this.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
        this.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.page, this.pagesize);
      }
    },
    //组织部门
    structureclick: function structureclick(val) {
      this.page = 1;
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.Buried.search_structure_ids = adad;
      this.statisticalTable(this.page, this.pagesize);
    },
    // 埋点列表筛选
    handleClick: function handleClick() {
      if (this.activeName == 'second') {
        this.page = 1;
        this.statisticalTable(this.page, this.pagesize);
      }
      this.structure_id = '';
      this.finalFollow = '';
      this.typeShow = false;
      this.operatorShow = false;
      this.userNameshow = false;
      this.BelongingShow = false;
      this.operationDataShow = false;
      this.Buried = {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      };
    },
    statisticalTable: function statisticalTable(page, pagesize) {
      var _this2 = this;
      this.Buried.page = page;
      this.Buried.pagesize = pagesize;
      this.Buried.auth_rule_pid = this.menuId;
      OperatorDataIndex(this.Buried).then(function (res) {
        _this2.BuriedData1 = res.data.data;
        _this2.total = res.data.total;
      });
    },
    getStructureSe: function getStructureSe() {
      var _this3 = this;
      //组织部门
      getStructureListArr().then(function (respomse) {
        _this3.bumenoptions = respomse.data;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.pagesize = val;
      this.statisticalTable(this.page, val);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.page = val;
      this.statisticalTable(val, this.pagesize);
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '操作人') {
        this.operatorShow = true;
      } else if (item === '用户名') {
        this.userNameshow = true;
      } else if (item === '操作日期') {
        this.operationDataShow = true;
      } else if (item === '操作人所属部门') {
        this.BelongingShow = true;
      } else if (item === '操作类型') {
        this.typeShow = true;
      }
    },
    Deletesshow: function Deletesshow(item) {
      this.page = 1;
      if (item === '操作人') {
        this.operatorShow = false;
        this.Buried.search_realname = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '用户名') {
        this.userNameshow = false;
        this.Buried.search_username = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作日期') {
        this.operationDataShow = false;
        this.Buried.search_create_time = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作人所属部门') {
        this.BelongingShow = false;
        this.structure_id = '';
        this.Buried.search_structure_ids = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作类型') {
        this.typeShow = false;
        this.Buried.search_auth_rule_title = '';
        this.statisticalTable(this.page, this.pagesize);
      }
    },
    buriedPoint: function buriedPoint(name) {
      var _this4 = this;
      this.buried.forEach(function (item) {
        if (item.title == name) {
          _this4.auth_rule_id = item.id;
        }
      });
      return this.auth_rule_id;
    },
    //获取列表
    GetList: function GetList() {
      var _this5 = this;
      var editForm = {
        structure_id: this.CallStatistics_id,
        stime: this.start_time,
        etime: this.end_time,
        auth_rule_id: this.auth_rule_id,
        uid: this.CallStatistics_teacherid
      };
      CallStatistics(editForm).then(function (res) {
        _this5.CallStatistics_list = res.data;
        _this5.loading = false;
      });
    },
    //顾问老师通讯录
    Departmentaddress: function Departmentaddress() {
      var _this6 = this;
      getUserList({
        type: '0',
        get_type: '6'
      }).then(function (res) {
        _this6.CallStatistics_teachername = res.data;
      });
    },
    //数据统计范围树状图
    getStructure: function getStructure() {
      var _this7 = this;
      getlower_structure_list({
        type: '1',
        get_type: '6'
      }).then(function (res) {
        _this7.bumenoptions = res.data;
      });
    },
    //时间
    DataTime: function DataTime() {
      if (this.CallStatistics_time == null) {
        this.start_time = '';
        this.end_time = '';
      } else {
        var s_time = this.CallStatistics_time[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.CallStatistics_time[1].toString();
        this.end_time = e_time.substring(0, 10);
      }
    },
    //数据统计范围
    handleChange: function handleChange(val) {
      if (val) {
        // this.CallStatistics_id = val.slice(-1).toString();
        var share = val.map(function (e) {
          return e[e.length - 1];
        }).toString(); // 部门的最后一位ID
        var adad = share == ',' ? '' : share;
        this.CallStatistics_id = adad.toString();
      }
    },
    //顾问老师查询
    handleFilter: function handleFilter() {
      // if (this.CallStatistics_teacherid != '') {
      //     this.CallStatistics_list = [];
      //     let editForm = {
      //         structure_id: this.CallStatistics_id,
      //         stime: this.start_time,
      //         etime: this.end_time,
      //         uid: this.CallStatistics_teacherid
      //     };
      //     CallStatistics(editForm).then(res => {
      //         // this.$nextTick(() => {
      //             this.CallStatistics_list = res.data;
      //         // });
      //         this.loading = false;
      //     });
      // } else {
      // }
    },
    // 设置滚动条相关信息1111111111
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    //重置按钮
    replacement: function replacement() {
      this.value1 = '';
      this.teachername = ''; //顾问老师查询
      this.bumen = '';
    },
    //合计
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '总价';
          return;
        }
        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        if (!values.every(function (value) {
          return isNaN(value);
        })) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' 元';
        } else {
          sums[index] = 'N/A';
        }
      });
      return sums;
    },
    search: function search() {
      this.buriedPoint('搜索');
      this.GetList();
    }
  }
};